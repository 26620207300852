@import '~@andes/empty-state/index';
@import '~@andes/button/index';
@import '~@andes/thumbnail/index';
@import '~@andes/typography/index';

body {
  font-family: 'Proxima Nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin: 0;
}

// Centers the whole screen error to avoid issues
.shop-not-found {
  align-items: center;
  background-color: $andes-gray-040-solid;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;

  .andes-button__content {
    font-size: $font-size-18;
  }
}

.shop-not-found__illustration {
  height: 160px;
  width: 264px;
}
